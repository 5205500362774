export default {
    ADD_ITEM(state, item) {
        state.AppointmentMarkups.unshift(item)
    },
    SET_AppointmentMarkup(state, AppointmentMarkups) {

        state.AppointmentMarkups = AppointmentMarkups
    },
    UPDATE_AppointmentMarkup(state, AppointmentMarkup) {
        const AppointmentMarkupIndex = state.AppointmentMarkups.findIndex((p) => p.ID == AppointmentMarkup.ID)
        Object.assign(state.AppointmentMarkups[AppointmentMarkupIndex], AppointmentMarkup)
    },
    SET_MarkupPatientCountries(state, MarkupPatientCountries) {
        state.MarkupPatientCountries = MarkupPatientCountries
    },
    SET_MarkupDoctorCountries(state, MarkupDoctorCountries) {
        state.MarkupDoctorCountries = MarkupDoctorCountries
    },
    SET_MarkupSpecialties(state, MarkupSpecialties) {
        state.MarkupSpecialties = MarkupSpecialties
    },
    SET_MarkupDoctors(state, MarkupDoctors) {
        state.MarkupDoctors = MarkupDoctors
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.AppointmentMarkups.findIndex((p) => p.ID == itemId)
        state.AppointmentMarkups.splice(ItemIndex, 1)
    },
}