<template>
  <div id="data-list-list-view">
  <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.push('/AppointmentMarkup') "
    />

    <div class="vx-row mt-3">
      <div class="vx-col lg:w-1/2">
        <vs-input
         type="number"
          min="0"
          class="w-full"
          label="Price From"
          v-model="AppointmentMarkupModel.PriceFrom"
        />
      </div>
      <div class="vx-col lg:w-1/2">
        <vs-input
          class="w-full"
           type="number"
          min="0"
          label="Price To"
          v-model="AppointmentMarkupModel.PriceTo"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Market source") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupPatientCountries"
          :options="patientCountries"
          :reduce="(ID) => ID.ID"
        />
      </div>
       <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Doctors’ destination") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupDoctorCountries"
          :options="doctorCountries"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>


     <div class="vx-row">
      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Specialties") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupSpecialties"
          :options="specialities"
          :reduce="(ID) => ID.ID"
        />
      </div>
       <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Doctors") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupDoctors"
          :options="Doctors"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>


    <div class="vx-row">
       <div class="vx-col lg:w-1/2">
        <vs-input
         type="number"
          min="0"
          class="w-full "
          label="Markup Fee "
          v-model="AppointmentMarkupModel.MarkupFee"
        />
      </div>
       <div class="vx-col lg:w-1/2">

        <vs-checkbox

          color="success "
          class="mt-8"
          v-model="AppointmentMarkupModel.IsPercentage"
          > Is Percentage</vs-checkbox
        >
      </div>


    </div>
    <div class="vx-row mt-5 justify-end">
      <vs-button class="mr-4" type="flat" @click="$router.go(-1)"
        >back</vs-button>

      <vs-button color="primary" type="filled" @click="submitData"
        >Save</vs-button
      >
    </div>



  </div>
</template>
<script>
import moduleAppointmentMarkup from "@/store/AppointmentMarkup/moduleAppointmentMarkup.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain } from "@/gloabelConstant.js";
import moduleSpecialty from '@/store/settings/specialty/moduleSpecialty.js';
export default {
  components: {  },
  data() {
    return {
      baseURL: domain,


      AppointmentMarkupModel: {
          MarkupSpecialties:[],
          MarkupDoctorCountries:[],
          MarkupPatiantCountries:[],
          MarkupDoctors:[],
          IsPercentage:false,



      },

    };
  },
  methods: {


    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
         this.AppointmentMarkupModel.MarkupPatiantCountries = this.$store.state.AppointmentMarkupList.MarkupPatientCountries.map(
        function(val) {
          var obj = {};
          obj.CountryID = val;
          return obj;
        }
      );

           this.AppointmentMarkupModel.MarkupDoctorCountries = this.$store.state.AppointmentMarkupList.MarkupDoctorCountries.map(
        function(val) {
          var obj = {};
          obj.CountryID = val;
          return obj;
        }
        );
     this.AppointmentMarkupModel.MarkupDoctors = this.$store.state.AppointmentMarkupList.MarkupDoctors.map(
        function(val) {
          var obj = {};
          obj.DoctorID = val;
          return obj;
        }
        );

     this.AppointmentMarkupModel.MarkupSpecialties = this.$store.state.AppointmentMarkupList.MarkupSpecialties.map(
        function(val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        }
        );
    debugger
          if (this.AppointmentMarkupModel.ID !== null && this.AppointmentMarkupModel.ID >= 0) {

            this.$store
              .dispatch("AppointmentMarkupList/UpdateAppointmentMarkup", this.AppointmentMarkupModel).then(res=>{
                window.showUpdateSuccess();
                  this.$router.go(-1)
              })
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete this.AppointmentMarkupModel.ID;
            this.$store
              .dispatch("AppointmentMarkupList/AddAppointmentMarkup", this.AppointmentMarkupModel).then(res=>{
                window.showUpdateSuccess();

                  this.$router.go(-1)
              })
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }
        }
      });
    }

  },
  computed: {

    patientCountries() {
      return this.$store.state.CountryList.countries;
    },
    doctorCountries() {
      return this.$store.state.CountryList.countries;
    },

    Doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    specialities() {
      return this.$store.state.SpecialtyList.specialities.filter(x=>x.ParentSpecialtyID == null);
    },
 MarkupPatientCountries: {
      get() {
        return this.$store.state.AppointmentMarkupList.MarkupPatientCountries;
      },
      set(newValue) {
        this.$store.commit("AppointmentMarkupList/SET_MarkupPatientCountries", newValue);
      }},

 MarkupDoctorCountries: {
      get() {
        return this.$store.state.AppointmentMarkupList.MarkupDoctorCountries;
      },
      set(newValue) {
        this.$store.commit("AppointmentMarkupList/SET_MarkupDoctorCountries", newValue);
      }
    },
 MarkupDoctors: {
      get() {
        return this.$store.state.AppointmentMarkupList.MarkupDoctors;
      },
      set(newValue) {
        this.$store.commit("AppointmentMarkupList/SET_MarkupDoctors", newValue);
      }
    },
 MarkupSpecialties: {
      get() {
        return this.$store.state.AppointmentMarkupList.MarkupSpecialties;
      },
      set(newValue) {
        this.$store.commit("AppointmentMarkupList/SET_MarkupSpecialties", newValue);
      }
    },
  },
  created() {
    if (!moduleAppointmentMarkup.isRegistered) {
      this.$store.registerModule("AppointmentMarkupList", moduleAppointmentMarkup);
      moduleAppointmentMarkup.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
      this.$store.dispatch("DoctorList/GetAllDoctors", {});
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    var AppointmentMarkupId = this.$route.params.Id;
    if (AppointmentMarkupId) {
      this.$store
        .dispatch("AppointmentMarkupList/GetAppointmentMarkup", AppointmentMarkupId)
        .then((res) => {
          this.AppointmentMarkupModel = res.data.Data;
          debugger;
         this.$store.commit(
       "AppointmentMarkupList/SET_MarkupPatientCountries",
          this.AppointmentMarkupModel.PatiantCountries.map(
          a => a.CountryID
         )
        );
         this.$store.commit(
       "AppointmentMarkupList/SET_MarkupDoctorCountries",
          this.AppointmentMarkupModel.DoctorCountries.map(
          a => a.CountryID
         )
        );
         this.$store.commit(
       "AppointmentMarkupList/SET_MarkupSpecialties",
          this.AppointmentMarkupModel.Specialties.map(
          a => a.SpecialtyID
         )
        );
         this.$store.commit(
       "AppointmentMarkupList/SET_MarkupDoctors",
          this.AppointmentMarkupModel.Doctors.map(
          a => a.DoctorID
         )
        );
    })

    }

  },
};
</script>
<style scoped>
.v-select {
  background: white;
}
.quill-editor {
  background: white;
}
.ql-snow {
  background: white;
}
</style>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
